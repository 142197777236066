import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
// import theme from '../../theme'
import { createTheme } from '../../theme/index'

import LandingThemeProvider from '../Landings/theme'

import SuspenseLoader from './SuspenseLoader'
import { CssBaseline } from '@mui/material'

const AccessConnectLanding = React.lazy(() => delayForDemo(import('../Landings/sections/_access_connect/view/MarketingLandingView')))
const MembershipConnectLanding = React.lazy(() => delayForDemo(import('../Landings/sections/_membership_connect/view/MarketingLandingView')))
const PropertyConnectLanding = React.lazy(() => delayForDemo(import('../Landing/Landing')))
const LaitenLanding = React.lazy(() => delayForDemo(import('../LaitenLanding/Landing')))
const Demo = React.lazy(() => delayForDemo(import('../LaitenLanding/Demo/Demo')))
const AccessControlLanding = React.lazy(() => delayForDemo(import('../LaitenLanding/AccessControl/Landing/AccessControlLanding')))
const AdministrationControlLanding = React.lazy(() => delayForDemo(import('../LaitenLanding/Administration/Landing/AdministrationControlLanding')))
const App = React.lazy(() => delayForDemo(import('../App/App')))

function delayForDemo(promise) {
  return new Promise(resolve => {
    setTimeout(resolve, 2)
  }).then(() => promise)
}

const AppFileProxy = props => {
  const location = useLocation()
  const history = useHistory()

  const theme = createTheme({
    colorPreset: 'laiten',
    contrast: 'high',
    direction: 'ltr',
    layout: 'vertical',
    navColor: 'evident',
    paletteMode: 'light',
    responsiveFontSizes: true,
    stretch: false,
  })

  const AccessConnectLandingWithTheme = () => {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <LandingThemeProvider>
          <AccessConnectLanding />
        </LandingThemeProvider>
      </Suspense>
    )
  }

  const MembershipConnectWithTheme = () => {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <LandingThemeProvider>
          <MembershipConnectLanding />
        </LandingThemeProvider>
      </Suspense>
    )
  }

  const PropertyConnectLandingWithTheme = () => {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <LandingThemeProvider>
          <PropertyConnectLanding />
        </LandingThemeProvider>
      </Suspense>
    )
  }

  const LaitenLandingWithTheme = () => {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
              <Route exact path='/' component={LaitenLanding} />
              <Route exact path='/laiten' component={LaitenLanding} />
              <Route exact path='/laiten/demo' component={Demo} />
              <Route exact path='/laiten/control-de-accesos' component={AccessControlLanding} />
              <Route exact path='/laiten/administracion' component={AdministrationControlLanding} />
            </Switch>
          </ThemeProvider>
        </StyledEngineProvider>
      </Suspense>
    )
  }

  const type = 'laiten'
  const landings = {
    accessConnect: {
      link: '/access-connect',
      component: <AccessConnectLandingWithTheme />,
      key: 'accessConnect'
    },
    membershipConnect: {
      link: '/membership-connect',
      component: <MembershipConnectWithTheme />,
      key: 'membershipConnect'
    },
    propertyConnect: {
      link: '/property-connect',
      key: 'propertyConnect',
      component: <PropertyConnectLandingWithTheme />
    },
    laiten: {
      link: '/laiten',
      key: 'laiten',
      component: <LaitenLandingWithTheme />
    }
  }

  if (location.pathname === landings.accessConnect.link) {
    return landings.accessConnect.component
  }

  if (location.pathname === landings.membershipConnect.link) {
    return landings.membershipConnect.component
  }

  if (location.pathname === landings.propertyConnect.link) {
    return landings.propertyConnect.component
  }

  if (location.pathname.includes(landings.laiten.link)) {
    return landings.laiten.component
  }

  if (location.pathname === '/') {
    switch (type) {
      case landings.accessConnect.key:
        return landings.accessConnect.component
      case landings.membershipConnect.key:
        return landings.membershipConnect.component
      case landings.propertyConnect.key:
        return landings.propertyConnect.component
      case landings.laiten.key:
        return landings.laiten.component
      case 'none':
        history.push('/inicio')
        return <></>
    }
  }
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  )
}

AppFileProxy.propTypes = {}

export default AppFileProxy
